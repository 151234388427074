import './WeHelp.css';
import * as d3 from "d3";
import { useState, useSyncExternalStore } from 'react';


function WeHelpDiagram({data, maxY}) {
console.log('WeHelpDiagramLine data', data);

const [heightY, setheightY] = useState(0);
if (heightY == 0) {
  setheightY(maxY + 9);
}

const screenWidth = useSyncExternalStore(function(onChange){
  window.addEventListener("resize", onChange);

  return () => window.removeEventListener("resize", onChange);
}, 
function () {
  return window.innerWidth;
} );

//const lengthAxisY = 500;
//const widthSvg = 1600;

const widthSvg = screenWidth/100 * 80;
const lengthAxisY = Math.round(widthSvg/3);//1/3 widthSvg

const getY = d3.scaleLinear()
  //.domain([0, Math.max(...coords) + 3])
  .domain([0, heightY])
  .range([lengthAxisY,0]);

const getX = d3.scaleLinear()
  .domain([0, data[1].length - 1])
  .range([0, widthSvg]);

const getYAxis = ref => {
  const yAxis = d3.axisLeft(getY)
    .tickSize(-widthSvg) // ширина горизонтальных линий на графике
    .tickPadding(7);
  d3.select(ref).call(yAxis);
};
const getXAxis = ref => {
  const xAxis = d3.axisBottom(getX)
  .tickSize(-lengthAxisY)
  //.tickFormat(x => data.days[x])
  .ticks(data[data.length - 1].length)//здесь нужно указать количество тиков на оси
  //.tickValues(data[data.length - 1])
  .tickFormat(x => data[data.length - 1][x])// здесь присваиваем оси x другие названия 
  .tickPadding(7);
  d3.select(ref).call(xAxis);
};

const curveFunc = d3.line()
.x(function(d) { return getX(d.x) })
.y(function(d) { return getY(d.y) })
//.curve(d3.curveMonotoneX);

//.bandwidth() - возвращает ширину каждой полосы


function x_params (objX) {
  let objX1 = [];
  for (let i = 0; i < objX.length; i++) {
    objX1.push({y: objX[i], x: i});
  }
  return objX1;
}

const buttonZoomPlus = <div className='buttonZoomPlus' onClick={function () {
  if (heightY > Math.floor(maxY / 7)) {
    setheightY(heightY - Math.floor(maxY / 7))
  }
}}>+</div>;
const buttonZoomMinus = <div className='buttonZoomMinus' onClick={function () {
  if (heightY < maxY) {
    setheightY(heightY + Math.floor(maxY / 7))
  }
}}>&mdash;</div>;

function linesDiagram (arr) {
  let lineArr = [];
  let count = 0;

  let signature_var = Math.round(widthSvg / (arr.length - 3));
  let half_signature_var = Math.round(signature_var/2);

  for (let i = 1; i < arr.length - 2; i++) { //отсеиваем массивы 0, arr.length - 2 и arr.length - 1  

    lineArr.push(<>
      <text
        fill="black"
        //x={ arr.length == 1 ? i * widthSvg / (arr.length - 3) - (180 - 13 * (arr.length - 6)) : widthSvg/2}
        //x={ i * widthSvg / (arr.length - 3) - (180 - 13 * (arr.length - 6))}
        x={  i * signature_var - half_signature_var - 5 }
        y={lengthAxisY + 70}
        textAnchor="middle">
        {arr[arr.length - 2][i - 1]}
      </text>
      <circle className={`circle${i}`}
        //cx={ arr.length == 1 ? i * widthSvg / (arr.length - 3) - (180 - 13 * (arr.length - 6)) : widthSvg/2 - 5}
        //cx={ i * widthSvg / (arr.length - 3) - (180 - 13 * (arr.length - 6))}
        cx={ i * signature_var - half_signature_var - 5}
        cy={lengthAxisY + 35}
        r={10}
        fill={arr[0][i - 1]}
      />
      </>);
    lineArr.push(<path className={`line${i}`} style={{fill: 'none'}} strokeWidth={3} stroke={arr[0][i - 1]} 
    d={curveFunc (x_params (arr[i]))}></path>);
    lineArr.push(<g>{arr[i].map( function(item, index) {//тег g не удалять! Он нужен для настройки css
      return <> <circle
        className={`points${i}`}
        key={index}
        cx={getX(index)}
        cy={getY(item)}
        r={4}
        fill={arr[0][i - 1]}
      />
      <text className='subscription'
      x={getX(index) + 5}
      y={getY(item) - 10}>
        {item}
      </text>
      </>
    })}</g>);
  }
  return lineArr;
}

const svg = <svg width={widthSvg} height={lengthAxisY} style={{borderRight: '1px solid #b3b3b3'}}>
<g className='YAxis' ref={getYAxis} stroke-width='0.3'/>
<g className='XAxis' y={lengthAxisY} ref={getXAxis} transform={`translate(0,${getY(0)})`} stroke-width='0.3'/>
{linesDiagram(data)}
</svg>;

return <>
  {svg}
  {buttonZoomPlus}
  {buttonZoomMinus}
</> ;
}

export default WeHelpDiagram;
