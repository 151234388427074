import { useSelector } from 'react-redux';

function CreaAIPage() {

const token = useSelector((state) => state.tokenOfSession.token);
console.log("CreaAIPage");
//const creaAIPage = <iframe src={`https://createch.com.tr:3000/?sessionId=${token}`} style={{width: '100vw', height: '95vh', border: 'none', marginTop: '1px'}} frameBorder={0}></iframe>;
  
let str = "https://createch.com.tr:3000/?sessionId=" + token;
const creaAIPage = <iframe src={str} style={{width: '100vw', height: '95vh', border: 'none', marginTop: '1px'}} frameBorder={0}></iframe>;

return creaAIPage;
}

export default CreaAIPage;

//https://createch.com.tr:3000/?sessionId=745c356c-38d2-42ac-b2d7-90418a488423