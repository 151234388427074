import { useState, useEffect, useRef } from 'react';

import Bufer_Fourth_Forms from './classBufer_Fourth_Forms';
import FourthFormUnit from './FourthFormUnit';


function FourthFormList() {

  const [updateComponent, setupdateComponent] = useState([0]);

  useEffect(() => {
    Bufer_Fourth_Forms.update_fourth_form = setupdateComponent;
    console.log("FourthFormList " + typeof Bufer_Fourth_Forms.update_fourth_form);
    return () => {
      Bufer_Fourth_Forms.update_fourth_form = 0;
      Bufer_Fourth_Forms.store = [0];
    };
  }, []); 
  /*
  if (Bufer_Fourth_Forms.update_fourth_form == 0) {
    Bufer_Fourth_Forms.update_fourth_form = setupdateComponent;
  }
  */

  let fourFormArr = [];
  console.dir(Bufer_Fourth_Forms.store);

  if (Bufer_Fourth_Forms.store[0] != 0 && Bufer_Fourth_Forms.store?.[0] != undefined && Bufer_Fourth_Forms.store.length != 0) {
    for (let i = 0; i < Bufer_Fourth_Forms.store.length; i++) {
      fourFormArr.push(<FourthFormUnit index={i} />);
      //fourFormArr.push(<p>!</p>);
    }
  }
  console.log("FourthFormList " + typeof Bufer_Fourth_Forms.update_fourth_form);




return <div className='FourthFormList'>

  {fourFormArr}
  
  

</div>;
}

export default FourthFormList;

