
import { useMemo, useState, useRef, useEffect} from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';

import { MRT_Localization_TR } from 'material-react-table/locales/tr';

import { Box } from '@mui/material';
import { useSelector } from 'react-redux';


import Bufer_Fourth_Forms from './classBufer_Fourth_Forms';
import fetchFunction from './fetchFunction_for_Alt_Form';
import StoreRequest from './classStoreRequestForm';

let speciesTableName = "fourthFormTable";
let count = -1; // эта переменная общая для всех компонентов
let mutationConfig = {
  childList: true, // наблюдать за непосредственными детьми
  subtree: true, // и более глубокими потомками
  //characterDataOldValue: true // передавать старое значение в колбэк
  characterData: true, 
}

function getRandomInt(min, max) {
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

function DinamikRaporTableFF ({data, columnsTable, parentIndex, index, }) {
  const token = useSelector((state) => state.tokenOfSession.token);

  const refIndexOfColorColumn = useRef(-1);
  const refParentTableLines = useRef(0);

  const refSelectedLine = useRef(0);
  const refBuferLength = useRef(0);

  const refUnicTableName = useRef(speciesTableName + (++count) + getRandomInt(0, 1000));

  let observer;

  useEffect(() => {
    // Выполнение при монтировании компонента
  
    observer = new MutationObserver(mutations => {
      for (let i = 0; i < refParentTableLines.current.length; i++) {
        console.log('ffTable ' + (refParentTableLines.current[i] === refSelectedLine.current));

        if (refParentTableLines.current[i]?.children?.[refIndexOfColorColumn.current]?.innerText != "" && refParentTableLines.current[i] !== refSelectedLine.current) {
          if (refParentTableLines.current[i].children[refIndexOfColorColumn.current].innerText.length == 7 && refParentTableLines.current[i].children[refIndexOfColorColumn.current].innerText[0] == "#") {
            refParentTableLines.current[i].style.cssText = "background-color: " + refParentTableLines.current[i].children[refIndexOfColorColumn.current].innerText + " !important";
          }
        }
      }

    });
    return () => {
      // Очистка (выполнится перед размонтированием компонента)
      observer.disconnect();
    };
  }, []); 

  
  if (Bufer_Fourth_Forms.store.length > refBuferLength.current) {
    refBuferLength.current = Bufer_Fourth_Forms.store.length;
  } else if (Bufer_Fourth_Forms.store.length < refBuferLength.current) {
    if (Bufer_Fourth_Forms.store.length == parentIndex + 1) {
      refSelectedLine.current.style.cssText = "background-color: none";
      refSelectedLine.current = 0;
    }
    refBuferLength.current = Bufer_Fourth_Forms.store.length;
  }
  /*
  Этот код позволяет снимать выделение строки, если цепочка таблиц уменьшилась, 
  и данный компонент последний в списке. refBuferLength позволяет отследить 
  изменение длины цепочки таблиц. 
  */


  if (refParentTableLines.current == 0 || refParentTableLines.current == undefined) {
    queueMicrotask(function() {

    let var_thead = document.getElementsByClassName(refUnicTableName.current);
    let var_thead1 = var_thead[0].children[0].children[1].children[0].children[0].children[0];
  
    refParentTableLines.current = var_thead[0].children[0].children[1];
    observer.observe(refParentTableLines.current, mutationConfig);
    refParentTableLines.current = var_thead[0].children[0].children[1].children[0].children[1].children;

    for (let i = var_thead1.children.length - 1; i >= 0; i--) {
      if (var_thead1.children[i].children[0].children[0].children[0].innerText == "RENK") {
        refIndexOfColorColumn.current = i;
        break;
      } 
    }

    for (let i = 0; i < refParentTableLines.current.length; i++) {
      refParentTableLines.current[i].children[refIndexOfColorColumn.current]
    }

    
    });
  
    /*
    здесь queueMicrotask используется для нахождения высоты div со стилем auto и 
    повторного рендеринга с уже найденным значением. Если этого не сделать, то в первый раз 
    нельзя установить плавное закрытие div, он закроется мгновенно.
    */
  } 

 
let tableDataVariable = columnsTable.map(function (item) {
  return {
    accessorKey: item, //access nested data with dot notation
    header: item,
  }
});

const columns = useMemo(
  () => tableDataVariable,
  [tableDataVariable],
);

const table = useMaterialReactTable({
  columns,
  data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
  muiTableBodyRowProps: ({ row, staticRowIndex }) => ({
    onDoubleClick: (event) => {
      console.log("tableFF index", index);
      if (Bufer_Fourth_Forms.store[parentIndex][index].FORM_DETAY.ALT_FORM) {
        if (refSelectedLine.current == 0) {
          refSelectedLine.current = event.target.parentElement;
          refSelectedLine.current.style.cssText = "background-color: #c8c7cc !important";
        } else {
          refSelectedLine.current.style.cssText = "background-color: none";
          refSelectedLine.current = event.target.parentElement;
          refSelectedLine.current.style.cssText = "background-color: #c8c7cc !important";
        }
        fetchFunction(Bufer_Fourth_Forms.store[parentIndex][index].FORM_DETAY.ALT_FORMLAR, row.original, token, parentIndex);
      }
    },
  }),
  localization: MRT_Localization_TR,
  enableDensityToggle: false,
  paginateExpandedRows: false,
  initialState: { pagination : { pageSize : 5 }, density: 'comfortable' },
});




let tableReturn = <Box
  sx={{
    width: '94vw',
    marginLeft: '1vw',
    marginBottom: '15px',
    '& .highlightedLine:hover td': {
      backgroundColor: '#c8c7cc',
    }
  }} className={refUnicTableName.current} >
    <MaterialReactTable table={table} />
  </Box>;


  return tableReturn;
};

export default DinamikRaporTableFF;

/*
N.B.
index - это индекс таблицы в компоненте FourthFormUnit. Кроме таблицы 
        могут выводиться и графики, и карты. 

parentIndex - это индекс компонента FourthFormUnit в компоненте FourthFormList. 
        Именно этот индекс нужно ложить в функцию fetchFunction.


*/
