import Bufer_Fourth_Forms from './classBufer_Fourth_Forms';

let requestFlag = true;

function fetchFunction(dataToDownload, lineObj, token, index) {
  
  console.dir(Bufer_Fourth_Forms.store);
  console.dir(dataToDownload);
  console.dir(lineObj);
  /*
  let arr0 = dataToDownload[0].ALT_FORM_SERVIS_PARAMETRE.split(";"); 
  
  let str0 = dataToDownload[0].ALT_FORM_SERVIS + "?sessionId=" + token + "&";
  for (let elem of arr0) {
    str0 += elem  + "=" + lineObj[elem] + "&";
  }
  str0 = str0.slice(0, str0.length - 1);

  console.log(str0);
  */

  if (requestFlag) {
    requestFlag = false;

    let dataArr = [];
  
    let fetchArr = [];
    for (let i = 0; i < dataToDownload.length; i++) {
      let arr0 = dataToDownload[i].ALT_FORM_SERVIS_PARAMETRE.split(";"); 
      let str0 = dataToDownload[i].ALT_FORM_SERVIS + "?sessionId=" + token + "&";
      for (let elem of arr0) {
        str0 += elem  + "=" + lineObj[elem] + "&";
      }
      str0 = str0.slice(0, str0.length - 1);
  
      let fetch0 = fetch(str0)
      .then(response => response.json())
      .then(function (json) {
        
        console.log(json);
        return json;
      });
  
      fetchArr.push(fetch0);
    }
  
    Promise.allSettled(fetchArr)
    .then((data) => {
      
      if (index == -1) {
        Bufer_Fourth_Forms.store = [0];
      } else {
        console.log("Bufer_Fourth_Forms.store.length " + (index + 1) + " " + Bufer_Fourth_Forms.store.length);
        Bufer_Fourth_Forms.store.length = index + 1;
        console.log("Bufer_Fourth_Forms.store.length " + (index + 1) + " " + Bufer_Fourth_Forms.store.length);

      }
      

  
      for (let i = 0; i < data.length; i++) {
        if (data?.[i]?.value?.[0] != undefined) {
          dataArr.push(data[i].value[0]);
        }
      }
      console.dir(dataArr);
      if (Bufer_Fourth_Forms.store[0] == 0) {
        console.log("fetchFunction");
        Bufer_Fourth_Forms.store[0] = dataArr;
      } else {
        Bufer_Fourth_Forms.store.push(dataArr);
      }
      Bufer_Fourth_Forms.update_fourth_form([0]);

      requestFlag = true;
    })


  }

  
}

export default fetchFunction;


//https://creaapi.com/crealog/CrealogMaster.svc/GetWeHelpIsEmriKapamaGunlukAltTablo/?sessionId=28abeaa4-abed-4e27-8159-32a4fb52620f&TARIH=24-02-2025&ID=19
//https://creaapi.com/crealog/CrealogMaster.svc/GetWeHelpIsEmriKapamaGunlukAltTablo/?sessionId=e11ca074-703d-401d-8779-c88bf2ec2cc1&TARIH=24-02-2025&ID=18

