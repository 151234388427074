import { useState } from 'react';
import './authorization.css';
import { useDispatch} from 'react-redux';

import adress from '../adress.js'
import flagOfMap from '../flagOfMap.js'


function AuthorizationPage() {
const dispatch = useDispatch();


console.log('authorization page');
const [keepLogged, setkeepLogged] = useState(false);
const [countOfSlides, setcountOfSlides] = useState(0);
const [dataOfUser, setdataOfUser] = useState(['', '']);
const [dataOfModalWindow, setdataOfModalWindow] = useState([false, '']);

function turnOverTheSlide (flag) {
  if (flag && countOfSlides < 0) {
    setcountOfSlides(countOfSlides + 35);
  } 
  if (!flag && countOfSlides > -70) {
    setcountOfSlides(countOfSlides - 35);
  }
}

function getData () {
  if(dataOfUser[0] != '' && dataOfUser[1] != '' &&
  dataOfUser[0] != null && dataOfUser[1] != null &&
  dataOfUser[0] != undefined && dataOfUser[1] != undefined &&
  dataOfUser[0] != false && dataOfUser[1] != false &&
  dataOfUser[0] != true && dataOfUser[1] != true &&
  dataOfUser[0] != 0 && dataOfUser[1] != 0) {
    //fetch(`https://creaapi.com/crealog/NewService.svc/GetLogin/?kullaniciAdi=${dataOfUser[0]}&sifre=${dataOfUser[1]}&pin=@@8526@@!!@@8526&pushId=0&dil=TURKCE&mobil=0`)
    //fetch(`https://creaapi.com/crealogKlon/CrealogMaster.svc/GetLogin/?kullaniciAdi=${dataOfUser[0]}&sifre=${dataOfUser[1]}&pin=@@8526@@!!@@8526&pushId=0&dil=TURKCE&mobil=0`)
    fetch(`https://creaapi.com/${adress[0]}/CrealogMaster.svc/GetLogin/?kullaniciAdi=${dataOfUser[0]}&sifre=${dataOfUser[1]}&pin=@@8526@@!!@@8526&pushId=0&dil=TURKCE&mobil=0`)
    .then(response => response.json())
    .then(function (json) {
      if (json?.GetLoginResult?.VALIDATION_MESSAGE == "Login islemi sirasina hata olustu.33") { 
        setdataOfModalWindow([true, json.GetLoginResult.VALIDATION_MESSAGE]);
      }
      if (json?.GetLoginResult?.VALIDATION_MESSAGE == null &&
        json?.GetLoginResult?.ADI != null &&
        json?.GetLoginResult?.EMAIL != null) {
        //dispatch({type: 'CHANGE_PAGE_NAME', pageName: 'homePage' });
        dispatch({type: 'CHANGE_PAGE_NAME', pageName: 'creaAIPage' });
        dispatch({type: 'GET_TOKEN', token: json.GetLoginResult.SESSION });
        dispatch({type: 'SET_USER_NAME', userName: json.GetLoginResult.ADI });
      }
    });
    setdataOfUser(['', '', dataOfUser[2]]);
  } else {
    setdataOfModalWindow([true, 'Kullanıcı adı veya şifre alanları boş']);
  }
}

function dataRecording (eventValue, fild) {
  setdataOfUser([...dataOfUser.slice(0, fild), eventValue, ...dataOfUser.slice(fild + 1)]);
 
}
let modalWindow = <div className='modalWindow'>
<div className='modalWindow_text'>{dataOfModalWindow[1]}</div>
<div>
  <button onClick={function () {
    setdataOfModalWindow([false, '']);
  }}>Tamam</button>
</div>
</div>
let Sidebars = <div className="AuthorizationWrapper">

  {dataOfModalWindow[0] && modalWindow}

  <div className='Sidebars'>
    <div className='LeftSidebar'>
     <div className='Sidebarswrapper' style={{marginLeft: `${countOfSlides}vw`}}>
      {countOfSlides < 0 && <button className='LeftButton' onClick={() => turnOverTheSlide (true)}>&#8249;</button>}
      {countOfSlides > -70 && <button className='RightButton' onClick={() => turnOverTheSlide (false)}>&#8250;</button>}

      <div className='OneLeftSidebar'></div>
      <div className='TwoLeftSidebar'></div>
      <div className='ThreeLeftSidebar'></div>
    </div>
  </div>
  <div className='RightSidebar'>
    <div className='wrapperEntrance'>
      <div className='logo'>Createch</div>
      <div className='greet'>
      <div>Welcome back,</div>
      <span>Please sign in to your account.</span>
      <a href='#'>Sign up now</a>
      </div>
      <div className='signinForm'>
          <label className='label1'>Login</label> 
          <input type='text' autoComplete={keepLogged ? 'on' : 'off'} name='login' className='input1' value={dataOfUser[0]} onChange={event => dataRecording (event.target.value, 0)}/>
          <label className='label2'>Password</label>
          <input type='password' name='pasword' className='input2' value={dataOfUser[1]} onChange={event => dataRecording (event.target.value, 1)}/>
          <div>
            <input type='checkbox' name='keepSystem' className='input3' onChange={() => setkeepLogged(!keepLogged) }/>
            <label className='lable3' >Keep me logged in</label>

          </div>
          <input type='button' className='input4' value="Sign in" onClick={getData}></input>
          <div className='SwitchToTestServer'>
            <input type='checkbox' name='keepSystem' className='input5' onChange={ function() {
              if (adress[0] == 'crealog') {
                adress[0] = 'crealogKlon';
              } else {
                adress[0] = 'crealog';
              }

            } }/>
            <label className='lable4' >Test sunucusuna git</label>
          </div>
          <div className='SwitchToMap'>
            <input type='checkbox' className='input6' onChange={ function() {
              flagOfMap[0] = !flagOfMap[0];
            } }/>
            <label className='lable5' >Yeni bir harita seçin</label>
          </div>
      </div>
    </div>
  </div>
</div>

  
</div>
  return (
    Sidebars

  );
}

export default AuthorizationPage;
