import { useEffect, useState, useInsertionEffect, useRef } from 'react';

//import response from './response';
import DinamikRaporTableFF from './DinamikRaporTable_fourth_form';
import WeHelpDiagramOne from '../../Dashboard/WeHelp/WeHelpDiagramOne1';
import WeHelpDiagramMultiple from '../../Dashboard/WeHelp/WeHelpDiagramMultiple1';
import WeHelpDiagramPie from '../../Dashboard/WeHelp/WeHelpDiagramPie1';
import SortDiagramLine from '../../Dashboard/WeHelp/multypleDiagramLine/SortDiagramLine';
import WeHelpDiagramLine1 from '../../Dashboard/WeHelp/WeHelpDiagramLine1';
import WeHelpDiagramSmoothLine from '../../Dashboard/WeHelp/WeHelpDiagramSmoothLine';

import MapComponent from './MapComponent';

import Bufer_Fourth_Forms from './classBufer_Fourth_Forms';

const color_1 = '#34C6CD';
const color_2 = '#FF3500';
const color_3 = '#B1F100';
const color_4 = '#FFF500';
const color_5 = '#7908AA';
const color_6 = '#FF7340';
const color_7 = '#FFAB00';
const color_8 = '#009E66';
const color_9 = '#AA66CC';

const colorArr = [color_1, color_2, color_3, color_4, color_5, color_6, color_7, color_8, color_9];

const diagramStyle = {
  height: 'auto',
  marginBottom: '0px',
  paddingBottom: '53px'
};


function setGrafikBarOne (response, componentNumber) {

  let arr = [];
  let maxY = 0;
  for (let i = 0;  i < response["GRAFIK"]["series"].length; i++) {
    arr.push({
      "value": response["GRAFIK"]["series"][i]["data"][0],
      "color": colorArr[i],
      "nameColumn": response["GRAFIK"]["series"][i]["name"],
    });
    if (maxY < response["GRAFIK"]["series"][i]["data"][0]) {
      maxY = response["GRAFIK"]["series"][i]["data"][0];
    }
  }
  
  return <div className='diagramWrapping oneDimensionalDiagram' style={diagramStyle}>
    <WeHelpDiagramOne data={arr} maxY={maxY} />
  </div>
}

function setGrafikBarTwo (response, componentNumber) {
  let arr = [];
  let signature = [];
  let maxY = 0;
  for (let i = 0;  i < response["GRAFIK"]["categories2"].length; i++) {
    let arr1 = [];
    for (let j = 0; j < response["GRAFIK"]["series"].length; j++) {
      console.log('MiddleComponentForThirdForm setGrafikBarTwo arr', response["GRAFIK"]["series"][j]["data"][i]);

      arr1.push({
        "value": response["GRAFIK"]["series"][j]["data"][i],
        "color": colorArr[j],
        "nameColumn": response["GRAFIK"]["series"][j]["name"],
      });
      if (maxY < response["GRAFIK"]["series"][j]["data"][i]) {
        maxY = response["GRAFIK"]["series"][j]["data"][i];
      }
    }
    arr.push(arr1);
  }
  for (let elem of arr[0]) {
    signature.push({
      "color": elem["color"],
      "nameColumn": elem["nameColumn"],
    });
  }
  console.log('MiddleComponentForThirdForm setGrafikBarTwo arr', arr);
  return <div className='diagramWrapping multidimensionalDiagram' style={diagramStyle}>
    <WeHelpDiagramMultiple data={arr} signature={signature} nameColumnGroup={response["GRAFIK"]["categories2"]} maxY={maxY} />
  </div>
}

function setGrafikPie (response, componentNumber) {
  let arr = [];
  let maxY = 0;
  let summOfValues = 0;
  for (let i = 0;  i < response["GRAFIK"]["series"].length; i++) {
    arr.push({
      displayElement: response["GRAFIK"]["series"][i]["name"],
      rate: response["GRAFIK"]["series"][i]["data"][0],
    });
    if (maxY < response["GRAFIK"]["series"][i]["data"][0]) {
      maxY = response["GRAFIK"]["series"][i]["data"][0];
    }
    summOfValues += response["GRAFIK"]["series"][i]["data"][0];
  }

  console.log('MiddleComponentForThirdForm setGrafikPie arr', arr);

  return <div className='diagramWrapping' style={diagramStyle}>
    <WeHelpDiagramPie data={arr} maxY={maxY} onePercent={summOfValues/100} />
  </div>
}

function setGrafikLine1 (response, componentNumber) {
  let arr = [];
  arr[0] = colorArr;
  let arrNamesVariable = [];
  let maxY = 0;
  for (let i = 0; i < response["GRAFIK"]["series"].length; i++) {
    arr.push(response["GRAFIK"]["series"][i]["data"]);
    arrNamesVariable.push(response["GRAFIK"]["series"][i]["name"]);

    let maxVariable = Math.max(...response["GRAFIK"]["series"][i]["data"]);
    if (maxY < maxVariable) {
      maxY = maxVariable;
    }
  }
  arr.push(arrNamesVariable);
  arr.push(response["GRAFIK"]["categories2"]);

  return <div className='diagramWrapping lineDiagram' style={diagramStyle}>
    <WeHelpDiagramLine1 data={arr} maxY={maxY} />
  </div>;
}

function setGrafikLine2 (response, componentNumber) {

  return <div className='diagramWrapping_for_multipleLine' style={diagramStyle}> 
  <WeHelpDiagramSmoothLine dates={response["GRAFIK"]["categories2"]} data={response["GRAFIK"]["series"][0]["data"]} 
  name={response["GRAFIK"]["series"][0]["name"]} color={colorArr[0]} />
  </div>;
}

function setGrafikLines (response, componentNumber) {

  return <SortDiagramLine signature={response["GRAFIK"]["categories2"]} data={response["GRAFIK"]["series"]} />;

}


function FourthFormUnit({index}) {
  
 
  //const refHeightSize = useRef(0);
  //const refPreviousValueOfTheDropdownList = useRef(0);
  const [componentNumber, setcomponentNumber] = useState(0);
  
  const refSignboard = useRef(0);
  const refFlagSignboard = useRef([false, 0, 0]);

  const refSignboardDiv = useRef(null);
  useEffect(() => {
    const signboardDiv = refSignboardDiv.current;

    function containerScrolling (event) {
      let deltaY = event.deltaY;
      let scrollLeft = signboardDiv.scrollLeft;
      if (deltaY !== 0 && event.deltaX === 0) {
        if (deltaY > 0) {
          requestAnimationFrame(() => signboardDiv.scrollLeft = scrollLeft + deltaY);
        } else if (deltaY < 0) {
          requestAnimationFrame(() => signboardDiv.scrollLeft = scrollLeft + deltaY);
        }
        event.preventDefault();
      }
    }

    signboardDiv.addEventListener('wheel', containerScrolling);

    return () => {
      signboardDiv.removeEventListener('wheel', containerScrolling);
    };
  }, []); 




let componentWithAdditionalInformation;
if (Bufer_Fourth_Forms.store[0] != 0 && Bufer_Fourth_Forms.store?.[index]?.[componentNumber] != undefined && Bufer_Fourth_Forms.store.length != 0) {
  console.log("grafic line 1 " + Bufer_Fourth_Forms.store[index]?.[componentNumber]?.FORM_TURU);
  switch (Bufer_Fourth_Forms.store[index]?.[componentNumber]?.FORM_TURU){
    case "GRAFIK_BAR_1":
      componentWithAdditionalInformation = setGrafikBarOne (Bufer_Fourth_Forms.store[index][componentNumber], componentNumber);
    break;
    case "GRAFIK_BAR_2":
      componentWithAdditionalInformation = setGrafikBarTwo (Bufer_Fourth_Forms.store[index][componentNumber], componentNumber);
    break;
    case "GRAFIK_PEI":
      componentWithAdditionalInformation = setGrafikPie (Bufer_Fourth_Forms.store[index][componentNumber], componentNumber);
    break;
    case "GRAFIK_LINE1":
      componentWithAdditionalInformation = setGrafikLine1 (Bufer_Fourth_Forms.store[index][componentNumber], componentNumber);
    break;
    case "GRAFIK_LINE2":
      componentWithAdditionalInformation = setGrafikLine2 (Bufer_Fourth_Forms.store[index][componentNumber], componentNumber);
    break;
    case "GRAFIK_LINE3":
      componentWithAdditionalInformation = setGrafikLines (Bufer_Fourth_Forms.store[index][componentNumber], componentNumber);
    break;
    case "DATA_TABLO":
      console.log("componentWithAdditionalInformation " + Bufer_Fourth_Forms.store[index]?.[componentNumber]?.FORM_TURU);

      let dataJson = JSON.parse(Bufer_Fourth_Forms.store[index][componentNumber].FORM_DATA);
      componentWithAdditionalInformation = <DinamikRaporTableFF data={dataJson} columnsTable={Object.keys(dataJson[0])} parentIndex={index} index={componentNumber} />;
    break;
    case "HARITA_LISTE":
      componentWithAdditionalInformation =   <>
      <div className='map'></div>
      <MapComponent class={'map'} HARITA_DATA={JSON.parse(Bufer_Fourth_Forms.store[index][componentNumber].HARITA_DATA)} />
      </>;
    break;
  }
}

/*
function containerScrolling (event) {
  if (event.deltaY !== 0) {
    console.log("containerScrolling " + event.deltaY);
    console.dir(event.target);
  }

  

}*/



return <div>
  <div className={'signboard_middleComponentForThirdForm'} ref={refSignboardDiv}> 
    {Bufer_Fourth_Forms.store[index].map(function(item, index0) {
      //console.dir(item);
      return <div className='stickerAlt' 
      style={{color: componentNumber == index0? '#4fa2fc' : '#383839', backgroundColor: item.TAB_RENGI}}
      onClick={()=> setcomponentNumber(index0)}>
        <p className='stickerHeader'> {item.FORM_ADI} </p>
      </div>
    })}
  </div>
  {componentWithAdditionalInformation}
  

</div>;
}

export default FourthFormUnit;


//      componentWithAdditionalInformation = <DinamikRaporTableFF data={dataJson} columnsTable={Object.keys(dataJson[0])} parentIndex={index} index={componentNumber} altFormFlag={Bufer_Fourth_Forms.store[index][componentNumber].FORM_DETAY.ALT_FORM} dataToDownload={} />;

