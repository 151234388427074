import { useEffect, useState, useInsertionEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import StoreRequest from './classStoreRequestForm';
import SecondForm from './secondForm';
import DinamikRaporRepository from './classDinamikRaporRepository';
import Bufer_AltForms_Table from './classBufer_AltForms_Table';
import Bufer_Fourth_Forms from './classBufer_Fourth_Forms';

import adress from '../../adress.js'

function inferenceOfSecondComponents (id, arr, indicatorOfCompletionOfFields, flag_refFlagForTrackingChangeOfPage) {
  if (arr?.length == undefined) {
    return;
  }
   
  let arr1 = [];
  for (let i = 0; i < arr.length; i++) {
    
    if (arr[i].KOLON_TIPI == 'ONAY_KUTUSU' && flag_refFlagForTrackingChangeOfPage) {
      DinamikRaporRepository.secondFormInputsState[i] = '';
    }
    

    if (arr[i].KOLON_TIPI != 'ONAY_KUTUSU') {
    //if (DinamikRaporRepository.secondFormInputsState[i] != 'true') {
      DinamikRaporRepository.secondFormInputsState[i] = arr[i].KOLON_DEGER ?? '';
    }
    console.log('middleComponent arr[i].KOLON_DEGER', arr[i].KOLON_DEGER, DinamikRaporRepository.secondFormInputsState[i], arr[i].KOLON_TIPI);
    arr1.push(<SecondForm id={id} index={i} indicatorOfCompletionOfFields={indicatorOfCompletionOfFields} />);
  }
  console.log('middleComponent inferenceOfSecondComponents DinamikRaporRepository.secondFormInputsState', DinamikRaporRepository.secondFormInputsState, flag_refFlagForTrackingChangeOfPage);
  return arr1;
}


const adress1 = "&skip=0&take=15&requireTotalCount=true";
const adress2 = "&&skip=1&take=1&requireTotalCount=false";

function fetchOfSecondComponents (id, currentValueOfTheDropdownList, token, setstatusUpdate, options, refForChangeThirdMiddle) {
  /*
  if (StoreRequest.store[id].thirdFormList != undefined && StoreRequest.store[id].thirdFormList != 0) {
    StoreRequest.store[id].thirdFormList = 0;
  }
  */
  
  StoreRequest.store[id].thirdFormList = {response1: '', response2: ''};
  let str = `${StoreRequest.store[id].dropDownList[currentValueOfTheDropdownList].SERVIS}?sessionId=${token}&formId=${StoreRequest.store[id].dropDownList[currentValueOfTheDropdownList].FORM_ID}&parametreler=${options}&kayitSayisi=-1`;
  
  //let str = String(StoreRequest.store[id].dropDownList[currentValueOfTheDropdownList].SERVIS) + '?sessionId=' + String(token) + '&formId=' + String(StoreRequest.store[id].dropDownList[currentValueOfTheDropdownList].FORM_ID) + '&parametreler=' + String(options) + '&kayitSayisi=-1';

  //str = JSON.stringify(str).replace('%22', '');
  //str = JSON.stringify(str);
  console.log('fetchOfSecondComponents str', str);
  
  //let str1 = 'https://creaapi.com/crealog/CrealogMaster.svc/GetWeHelpIsEmriKapamaGunluk/?sessionId=e17b5d5b-6b96-422a-86c3-3d206af9e28b&formId=4923&parametreler=ILK_TARIH;09.07.2024%2000:00|SON_TARIH;09.07.2024%2023:59:59|TUR;false|DEPARTMAN;false|STATU;false&kayitSayisi=-1';
  //let str2 = 'https://creaapi.com/crealog/CrealogMaster.svc/GetWeHelpIsEmriKapamaGunluk/?sessionId=e17b5d5b-6b96-422a-86c3-3d206af9e28b&formId=4923&parametreler=ILK_TARIH;10.07.2024%2000:00|SON_TARIH;11.07.2024%2023:59|TUR;false|DEPARTMAN;false|STATU;false&kayitSayisi=-1';

  let fetch1 = fetch((str + adress1))
  .then(response => response.json())
  .then(function (json) {
    
    console.log("форма вызвала fetch");
    console.log("middleComponent fetchOfSecondComponents StoreRequest.store[id].secondFormList", StoreRequest.store[id]);

    //StoreRequest.store[id].thirdFormList = json;
    StoreRequest.store[id].thirdFormList.response1 = json;
    console.log("middleComponent fetchOfSecondComponents StoreRequest.store[id].secondFormList", StoreRequest.store[id]);

    //refForChangeThirdMiddle[0] = true;
    //setstatusUpdate([0]);
  });

  let fetch2 = fetch((str + adress2))
  .then(response => response.json())
  .then(function (json) {
    
    //StoreRequest.store[id].thirdFormList = json;
    StoreRequest.store[id].thirdFormList.response2 = json;
  });

  Promise.allSettled([fetch1, fetch2])
  .then(([data1, data2]) => {
    console.log('Оба запроса завершены');
    console.log('Данные из первого запроса:', data1);
    console.log('Данные из второго запроса:', data2);
    // Тут выполняем нужное действие, когда оба запроса завершены
    refForChangeThirdMiddle[0] = true;

    Bufer_Fourth_Forms.update_fourth_form = 0;
    Bufer_Fourth_Forms.store = [0];
    
    setstatusUpdate([0]);
  })
}


function middleComponent({id, currentValueOfTheDropdownList, setUpdate_preDinamikRapor, refForChangeThirdMiddle}) {
if (StoreRequest.store[id]?.secondFormList == undefined) {
  return; 
}

console.log('middleComponent StoreRequest.store[id]', StoreRequest.store[id], currentValueOfTheDropdownList);

const token = useSelector((state) => state.tokenOfSession.token);
const [statusUpdate, setstatusUpdate] = useState([0]);
const refHeightSize = useRef(0);
const refPreviousValueOfTheDropdownList = useRef(0);
const indicatorOfCompletionOfFields = useRef(true);
const refStr = useRef('');
const refFlagForTrackingChangeOfPage = useRef(0);
let flag_refFlagForTrackingChangeOfPage = false; 
if (refFlagForTrackingChangeOfPage.current != currentValueOfTheDropdownList) {
  flag_refFlagForTrackingChangeOfPage = true;
  refFlagForTrackingChangeOfPage.current = currentValueOfTheDropdownList;
  refStr.current = 0;
}

console.log('middleComponent flag_refFlagForTrackingChangeOfPage', flag_refFlagForTrackingChangeOfPage);



const [heightSize, setheightSize] = useState('auto');
if (refPreviousValueOfTheDropdownList.current != currentValueOfTheDropdownList) {
  refPreviousValueOfTheDropdownList.current = currentValueOfTheDropdownList; 
  refHeightSize.current = 0;
  setheightSize('auto');
}

if (refHeightSize.current == 0) {
  queueMicrotask(function() {
    //console.log('middleComponent queueMicrotask document', document.getElementsByClassName('middleComponentConteiner')[0].offsetHeight);
    refHeightSize.current = document.getElementsByClassName('middleComponentConteiner')[0].offsetHeight + 5;
    setheightSize(`${refHeightSize.current}px`);
  });

  /*
  здесь queueMicrotask используется для нахождения высоты div со стилем auto и 
  повторного рендеринга с уже найденным значением. Если этого не сделать, то в первый раз 
  нельзя установить плавное закрытие div, он закроется мгновенно.
  */
} 


return <div>
  <div
  className='nameplate_middleComponent'
  onClick={function (event) {
    console.log('middleComponent refHeightSize.current', refHeightSize.current);
    if (heightSize != '0px') {
      setheightSize('0px');
    } else {
      setheightSize(`${refHeightSize.current}px`)
    }
  }} >
    <p className='header_nameplate_middleComponent'>ARAMA PARAMETLERİ</p>
    <p className='lastText_nameplate_middleComponent'>{heightSize == '0px' ? <span>&#9660;</span> : <span>&#9650;</span>} </p>
  </div>
  <div 
  className='middleComponentConteiner'
  onClick={ function (event) {
    console.log('middleComponent event', event);
    //event.target.children
    let sum = 0;
    for (let elem of event.target.children) {
      sum += elem.offsetHeight;
    }
    console.log('middleComponent sum', sum);
    }} style={{height: heightSize}} >
 {inferenceOfSecondComponents(id, StoreRequest.store[id].secondFormList, indicatorOfCompletionOfFields.current, flag_refFlagForTrackingChangeOfPage)}
  <button className='middleComponentButton' onClick={ function () {
    console.log('middleComponent button', StoreRequest.store[id].secondFormList);
    let str = '';
    for (let i = 0; i < StoreRequest.store[id].secondFormList.length; i++) {
      if(StoreRequest.store[id].secondFormList[i].BOS_OLABILIRMI == 0 && DinamikRaporRepository.secondFormInputsState[i] == '') {
        indicatorOfCompletionOfFields.current = false;
        setstatusUpdate([0]);
        return;
      }
      if(StoreRequest.store[id].secondFormList[i].BOS_OLABILIRMI == 1 && DinamikRaporRepository.secondFormInputsState[i] == '') {
        continue;
      }


      if(i == StoreRequest.store[id].secondFormList.length - 1) {
        str += `${StoreRequest.store[id].secondFormList[i].KOLON_ADI};${DinamikRaporRepository.secondFormInputsState[i]}`;
      } else {
        str += `${StoreRequest.store[id].secondFormList[i].KOLON_ADI};${DinamikRaporRepository.secondFormInputsState[i]}|`;
      }


    }
    console.log('middleComponent button', str, indicatorOfCompletionOfFields.current);
    if (!indicatorOfCompletionOfFields.current) {
      indicatorOfCompletionOfFields.current = true;
      console.log('middleComponent button', str);
      //setstatusUpdate([0]);
    }
    console.log('middleComponent str', str);

    //if (refStr.current != str) {
      refStr.current = str;
      Bufer_AltForms_Table.middleComponentVariable[0] = true;
      console.log('middleComponent Bufer_AltForms_Table.middleComponentVariable[0]', Bufer_AltForms_Table.middleComponentVariable[0]);
      fetchOfSecondComponents (id, currentValueOfTheDropdownList, token, setUpdate_preDinamikRapor, str, refForChangeThirdMiddle);
    //}

  }} >Ara</button>
  
  </div>
</div>;
}

export default middleComponent;


//string.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&');

