
import { useMemo, useState, useRef, useEffect} from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';

import { MRT_Localization_TR } from 'material-react-table/locales/tr';

import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

import fetchFunction from './fetchFunction_for_Alt_Form';


import StoreRequest from './classStoreRequestForm';

let speciesTableName = "thirdFormTable";
let count = -1; // эта переменная общая для всех компонентов
let mutationConfig = {
  childList: true, // наблюдать за непосредственными детьми
  subtree: true, // и более глубокими потомками
  //characterDataOldValue: true // передавать старое значение в колбэк
  characterData: true, 
}

function DinamikRaporTable ({data, columnsTable, id, divFlag, currentValueOfTheDropdownList, refForChangeThirdMiddle, name, index,  altFormFlag, dataToDownload}) {
  const refHeightSize = useRef('auto');
  const token = useSelector((state) => state.tokenOfSession.token);

  const refDOMTableElement = useRef(0);
  const refIndexOfColorColumn = useRef(-1);
  const refParentTableLines = useRef(0);

  const refSelectedLine = useRef(0);
  
  const refUnicTableName = useRef(speciesTableName + (++count));

  let observer;

  useEffect(() => {
    // Выполнение при монтировании компонента
  
    observer = new MutationObserver(mutations => {

      for (let i = 0; i < refParentTableLines.current.length; i++) {
        if (refParentTableLines.current[i]?.children?.[refIndexOfColorColumn.current]?.innerText != "" && refParentTableLines.current[i] !== refSelectedLine.current) {
          if (refParentTableLines.current[i].children[refIndexOfColorColumn.current].innerText.length == 7 && refParentTableLines.current[i].children[refIndexOfColorColumn.current].innerText[0] == "#") {
            console.log(refParentTableLines.current[i]);
            refParentTableLines.current[i].style.cssText = "background-color: " + refParentTableLines.current[i].children[refIndexOfColorColumn.current].innerText + " !important";
  
          }

        }
      }


      if (refDOMTableElement.current?.[index]?.children?.[0]?.offsetHeight != undefined && refDOMTableElement.current[index].style.height != '0px') {
        let tableHeight = Number(refDOMTableElement.current?.[index]?.children?.[0]?.offsetHeight) + 5;
        if (tableHeight != refHeightSize.current) {
            refHeightSize.current = tableHeight;
            refDOMTableElement.current[index].style.height = String(refHeightSize.current) + 'px';

        }
      }


    });
    return () => {
      // Очистка (выполнится перед размонтированием компонента)
      console.log("DinamikRaporTable был размонтирован в return useEffect");
      observer.disconnect();
    };
  }, []); 

  
  

  if (refHeightSize.current == 'auto' && (refDOMTableElement.current == 0 || refDOMTableElement.current == undefined)) {
    queueMicrotask(function() {


      refDOMTableElement.current = document.getElementsByClassName('MiddleComponentForThirdForm');

      if (refHeightSize.current == 'auto' && refDOMTableElement.current?.[index] != undefined && refDOMTableElement.current?.[index] != 0) {
        console.log('DinamikRaporTable refHeightSize.current', refHeightSize.current, refDOMTableElement.current?.[index]?.offsetHeight);
        refDOMTableElement.current[index].style.height = 'auto';
        refHeightSize.current = refDOMTableElement.current?.[index]?.offsetHeight + 5;
        refDOMTableElement.current[index].style.height = '0px';

      }



    if (refIndexOfColorColumn.current == -1 || refParentTableLines.current == 0) {
      let var_thead = document.getElementsByClassName(refUnicTableName.current);
      let var_thead1 = var_thead[0].children[0].children[1].children[0].children[0].children[0];
    
      refParentTableLines.current = var_thead[0].children[0].children[1];
      observer.observe(refParentTableLines.current, mutationConfig);
      refParentTableLines.current = var_thead[0].children[0].children[1].children[0].children[1].children;

      for (let i = var_thead1.children.length - 1; i >= 0; i--) {
        if (var_thead1.children[i].children[0].children[0].children[0].innerText == "RENK") {
          refIndexOfColorColumn.current = i;
          break;
        } 
      }
      

      for (let i = 0; i < refParentTableLines.current.length; i++) {
        console.log(refParentTableLines.current[i].children[refIndexOfColorColumn.current]);
        refParentTableLines.current[i].children[refIndexOfColorColumn.current]
      }

    }
    });
  
    /*
    здесь queueMicrotask используется для нахождения высоты div со стилем auto и 
    повторного рендеринга с уже найденным значением. Если этого не сделать, то в первый раз 
    нельзя установить плавное закрытие div, он закроется мгновенно.
    */
  } 

 
let tableDataVariable = columnsTable.map(function (item) {
  return {
    accessorKey: item, //access nested data with dot notation
    header: item,
  }
});

const columns = useMemo(
  () => tableDataVariable,
  [tableDataVariable],
);
console.log('DinamikRaporTable data', data);

const table = useMaterialReactTable({
  columns,
  data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
  muiTableBodyRowProps: ({ row, staticRowIndex }) => ({
    onDoubleClick: (event) => {

      if (altFormFlag) {
        if (refSelectedLine.current == 0) {
          refSelectedLine.current = event.target.parentElement;
          refSelectedLine.current.style.cssText = "background-color: #c8c7cc !important";
        } else {
          refSelectedLine.current.style.cssText = "background-color: none";
          refSelectedLine.current = event.target.parentElement;
          refSelectedLine.current.style.cssText = "background-color: #c8c7cc !important";
        }
        fetchFunction(dataToDownload, row.original, token, -1);
      }
    },
  }),
  localization: MRT_Localization_TR,
  enableDensityToggle: false,
  paginateExpandedRows: false,
  initialState: { pagination : { pageSize : 5 }, density: 'comfortable' },
});



let tableReturn;
if (divFlag) {
  tableReturn = <>
  <div
  className='nameplate_MiddleComponentForThirdForm'
  onClick={function (event) {
    if (refDOMTableElement.current != 0 && refDOMTableElement.current != undefined) {
      if(refDOMTableElement.current?.[index]?.offsetHeight != 0) {
        refDOMTableElement.current[index].style.height = '0px';
        refDOMTableElement.current[index].previousElementSibling.children[1].children[0].innerHTML = '&#9650;';
      } else {
        refDOMTableElement.current[index].style.height = String(refHeightSize.current) + 'px';
        refDOMTableElement.current[index].previousElementSibling.children[1].children[0].innerHTML = '&#9660;';
      }
    }
  }} >
    <p className='header_nameplate_MiddleComponentForThirdForm'> {name} </p>
    <p className='lastText_nameplate_MiddleComponentForThirdForm'><span>&#9650;</span></p>
  </div>
  
  <div className='MiddleComponentForThirdForm'>
    <Box
    sx={{
      width: '94vw',
      marginLeft: '1vw',
      marginBottom: '15px',
      '& .highlightedLine:hover td': {
        backgroundColor: '#c8c7cc',
      }
    }} className={`${id}` + ' ' + refUnicTableName.current} >
      <MaterialReactTable table={table} />
    </Box>
  </div>
  </>
} else {
  tableReturn = <Box
  sx={{
    width: '94vw',
    marginLeft: '1vw',
    marginBottom: '15px',
    '& .highlightedLine:hover td': {
      backgroundColor: '#c8c7cc',
    }
  }} className={`${id}` + ' ' + refUnicTableName.current} >
    <MaterialReactTable table={table} />
  </Box>
}

  return tableReturn;
};

export default DinamikRaporTable;


/*
[
    "https://creaapi.com/crealog/CrealogMaster.svc/GetWeHelpIsEmriKapamaGunlukAltTablo/?sessionId=15214095-8b9d-427f-a8e8-67a15b134341&TARIH08-10-2024&ID2",
    "https://creaapi.com/crealog/CrealogMaster.svc/GetWeHelpIsEmriKapamaGunlukHarita0/?sessionId=15214095-8b9d-427f-a8e8-67a15b134341&TURUARIZA&DEPARTMANITEKNİK DESTEK",
    "https://creaapi.com/crealog/CrealogMaster.svc/GetWeHelpIsEmriKapamaGunlukHarita1/?sessionId=15214095-8b9d-427f-a8e8-67a15b134341&TARIH08-10-2024&USER_STATUTD SERVİS GEREKLİ",
    "https://creaapi.com/crealog/CrealogMaster.svc/GetWeHelpIsEmriKapamaGunlukHarita2/?sessionId=15214095-8b9d-427f-a8e8-67a15b134341&TARIH08-10-2024&USER_STATUTD SERVİS GEREKLİ"
]
*/

/*

Существует проблема выделения строк в таблице: 
1. При нажатии на выделенную строку выделение снимается, а таблица не исчезает. 
2. При нажатии на другую строку выделение появляется, но таблица исчезает. 
3. Если строка выделена, то сначала нужно снять это выделение, а потом выделить еще раз, чтобы
   таблица появилась. 

   Что может решить эту проблему? Выделение и запрос как то связаны. 

   Итак 3 условия: 
  1. !divFlag - не влияет
  2. refFlagOfRequest.current - предназначен для того, чтобы в одно время выполнялась только одна 
     группа запросов. В начале выполнения кода принимает значение false, а в конце принимает значение 
     nrue. Не влияет на проблему. 

  3. Bufer_AltForms_Table.tableIndex[0] != staticRowIndex - проверяет на какую строку в таблице 
     было нажатие и не допускает повторного нажатия. Влияет на проблему. Условие true только 
     когда нажимается другая строка. Или же повторно выбирается та же строка.

  Похоже проблема именно в этой строке Bufer_AltForms_Table.refVariable[0] = !Bufer_AltForms_Table.refVariable[0];
  Решением проблемы было бы изменение состояния этой переменной. Но как это сделать? 
  Можно попробовать изменить состояние переменной перед этим. 

*/

/*

Нужно сделать переключатель строк в родительской таблице и переключатель дочерних 
элементов заново. 
Алгоритм: 
1. При нажатии на строку должна быть проверка на ее активность. 
2. Если строка неативна, то делаем запрос на сервер и выводим компоненты новой таблицы. 
3. Присваиваем класс highlight для активной строки. 
4. Если строка активна, то убираем класс highlight для данной строки и убираем компоненты новой 
   таблицы. 
5. Есть также вариант, когда выбирается другая строка. В таком случае класс highlight убирается 
   с последней выбранной строки, а действия флага для запроса и выбора таблицы такое же, как и раньше.


Сейчас ситуация лучше, однако возникают проблемы при клике на одну и ту же строку. 
Таблица опять показывается на 3 двойной клик. 
Проблема решена. 

Теперь возникла новая проблема, флаг для указания открытого состояния 
находится в положении true, хотя в хуке useEffect в компоненте DinamikRapor_AltForms 
ставится в значение false. 
Вероятно причина в том, что флаг срабатывает для вывода компонента DinamikRapor_AltForms, 
то есть до срабатывания useEffect. 
Нужно как то ставить флаг в положение false после размонтирования компонента.


*/

/*

Возникает проблема с открытием и закрытием таблиц. Было бы хорошо изменять высоту контейнера 
для таблицы по клику на контейнер. Но ссылка появится только после 1 загрузки таблицы на страницу, 
а до этого туда нужно поставить "auto". 

Теперь можно изменять размер контейнера прямо через dom, но как это согласовать со 
стилем самого элемента? 
Решено. 

Следующая проблема, не во всех таблицах можно открыть alt form. 
Компоненты alt form не выводятся, если они не должны открываться. 
Однако если сначала открыть таблицы, в которых alt form есть, а потом 
открыть таблицы, где alt form нет, то в хранилище запишется функция, 
которая при двойном клике вызовет ошибку в таблице, где alt form нет. 

Нужно эту функцию удалять перед размонтированием компонента. 
Возможно лучше просто передать флаг через props.

*/

//"MuiSelect-select MuiSelect-standard MuiInputBase-input MuiInput-input css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input"