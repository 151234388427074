import './dinamikRapor.css';
import { useState, useInsertionEffect, useLayoutEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

//import Form from './Form.js';
import StoreRequest from './classStoreRequestForm';
import DropDownList from './dropDownList';
import MiddleComponent from './middleComponent';
import MiddleComponentForThirdForm from './middleComponentForThirdForm';



import adress from '../../adress.js'


const svgLoader = <svg className='loader' viewBox="0 0 32 32">
<path d="M16 0c-8.711 0-15.796 6.961-15.995 15.624 0.185-7.558 5.932-13.624 12.995-13.624 7.18 0 13 6.268 13 14 0 1.657 1.343 3 3 3s3-1.343 3-3c0-8.837-7.163-16-16-16zM16 32c8.711 0 15.796-6.961 15.995-15.624-0.185 7.558-5.932 13.624-12.995 13.624-7.18 0-13-6.268-13-14 0-1.657-1.343-3-3-3s-3 1.343-3 3c0 8.837 7.163 16 16 16z"></path>
</svg>


function preDinamikRapor({id}) {

const flagOfFirstload = useRef(0);
if (flagOfFirstload.current == 0) {
  StoreRequest.store[id] = 0;
  flagOfFirstload.current = 1;
}
/*
useLayoutEffect(() => {
  StoreRequest.store[id] = 0;
}, []);
*/ 

const refForChangeThirdMiddle = useRef([false]);
/*
этот ref нужен чтобы регулировать высоту контейнера для таблицы в 3 компоненте. 
Если ref принимает значение true, то контейнер обновляется и корректирует своб высоту. 
ref может принять значение true во время нажатия кнопки ara во втором компоненте.
*/

console.log("форма попала в выводимый компонент");
const token = useSelector((state) => state.tokenOfSession.token);
//const [datapreFormPage, setdatapreFormPage] = useState(0);
const [dataUpdate, setUpdate] = useState([0]);
const [currentValueOfTheDropdownList, setcurrentValueOfTheDropdownList] = useState(-1);
/*
значение currentValueOfTheDropdownList используется не только для проверки 
изменения страницы, он также используется для выбора адреса для данных middleComponentForThirdForm. 
Адреса отличаются друг от друга и записаны в присылаемых данных для dropDownList.
Запрос с использованием этих данных совершается в middleComponent. 
*/


console.log('preDinamikRapor currentValueOfTheDropdownList', currentValueOfTheDropdownList);



if (StoreRequest.store[id] == undefined || StoreRequest.store[id] == 0) {
  //fetch(`https://creaapi.com/crealogKlon/CrealogMaster.svc/GetRaporParemetreler/?sessionId=${token}&formId=${id}`)
  //fetch(`https://creaapi.com/crealogKlon/CrealogMaster.svc/GetDinamikRapor/?sessionId=${token}&formId=${id}`)
  fetch(`https://creaapi.com/${adress[0]}/CrealogMaster.svc/GetDinamikRapor/?sessionId=${token}&formId=${id}`)
  .then(response => response.json())
  .then(function (json) {
    
    console.log("форма вызвала fetch");
    /*
    StoreRequest.store[id] = (json.GetRaporParemetrelerResult).map(function (item) {
      return {
        GORUNEN_ADI: item.GORUNEN_ADI, 
        ID: item.ID,     
        KOLON_DEGER_TIPI: item.KOLON_DEGER_TIPI,
        VIEW_DATA: item.VIEW_DATA,
        ID: item.ID,
      } 
    });
    */
    StoreRequest.store[id] = { dropDownList: json.GetDinamikRaporResult};

    setUpdate([0]);
    console.log('fetch', StoreRequest.store[id]);
  });
}

let preDinamikRaporComponent;
let secondFormList;
let thirdFormList;

if (StoreRequest.store[id] != undefined && StoreRequest.store[id] != 0) {  
  preDinamikRaporComponent  = <DropDownList id={id} data={StoreRequest.store[id].dropDownList} setcurrentValueOfTheDropdownList={setcurrentValueOfTheDropdownList} />

  console.log('preDinamikRapor', StoreRequest.store[id].secondFormList != undefined || StoreRequest.store[id].secondFormList != 0, StoreRequest.store[id].secondFormList);
  if (StoreRequest.store[id].secondFormList != undefined && StoreRequest.store[id].secondFormList != 0) {
    console.log('preDinamikRapor StoreRequest.store[id].secondFormList', StoreRequest.store[id].secondFormList);
    secondFormList = <MiddleComponent id={id} currentValueOfTheDropdownList={currentValueOfTheDropdownList[0]} setUpdate_preDinamikRapor={setUpdate} refForChangeThirdMiddle={refForChangeThirdMiddle.current} />;
  }

  console.log('preDinamikRapor StoreRequest.store[id].thirdFormList', StoreRequest.store[id].thirdFormList);

  if (StoreRequest.store[id].thirdFormList != undefined && StoreRequest.store[id].thirdFormList != 0) {
    
    console.log('preDinamikRapor StoreRequest.store[id].thirdFormList', StoreRequest.store[id].thirdFormList);
    let res_var = StoreRequest.store[id].thirdFormList?.response1;
    console.log("response1: " + res_var + ", response2: " + StoreRequest.store[id].thirdFormList.response2);
    console.dir(StoreRequest.store[id].thirdFormList.response1);
    console.log('preDinamikRapor StoreRequest.store[id].thirdFormList', StoreRequest.store[id].thirdFormList);
    thirdFormList = <MiddleComponentForThirdForm id={id} currentValueOfTheDropdownList={currentValueOfTheDropdownList[0]} response1={StoreRequest.store[id].thirdFormList.response1} response2={StoreRequest.store[id].thirdFormList.response2} refForChangeThirdMiddle={refForChangeThirdMiddle.current} />;
  }

}


  return <>
    <div className='PreFormPageConteiner'> 
      {preDinamikRaporComponent}
      {secondFormList}
      {thirdFormList}
      
    </div>
  </>;
}

export default preDinamikRapor;

/*
как сделать формы здесь?
В формах для изменения таблиц использовалось хранилище, в которое записывались данные из 
всех полей. Нужно ли такое хранилище здесь? Ведь на сервер данные все равно не отправляются. 
Да, здесь хранилище тоже нужно, так как нужно собирать данные для отправки на сервер. 

Может сделать еще один компонент для посредничества? Будет проще закрывать окно с компонентами.
Может использовать хранилища в tableComponent? Это неочевидно. Это что-то экономит? 

Сначала можно сделать компонент посредник, потом можно сделать местное хранилище, 
потом можно подключить поля.  

Как бы теперь удалять данные из хранилища после закрытия страницы? 
Можно использовать хук useInsertionEffect чтобы при первой загрузке страницы удалить все 
данные из хранилища. Все равно другие сомпоненты не должны использовать данные под id данной страницы. 

Нужно связать хранилище и компонент secondForm

*/

//      <MiddleComponentForThirdForm id={id} currentValueOfTheDropdownList={currentValueOfTheDropdownList[0]} />

