class Bufer_Fourth_Forms {

  static update_fourth_form = 0;
  static store = [0];

  static refVariable = [false];
  static stateVariable = [];
  static tableIndex = [-1];
  static tableDataVariable = {};
  static componentUpdateVariable = [];



  

}

export default Bufer_Fourth_Forms;

/*
Это буфер для обмена данными между параллельными компонентами DinamikRapor_AltForms и DinamikRaporTable, 
чтобы не обновлять родительский компонент. 
*/

