import { useEffect, useState, useInsertionEffect, useRef } from 'react';

//import response from './response';
import DinamikRaporTable from './DinamikRaporTable';
import WeHelpDiagramOne from '../../Dashboard/WeHelp/WeHelpDiagramOne1';
import WeHelpDiagramMultiple from '../../Dashboard/WeHelp/WeHelpDiagramMultiple1';
import WeHelpDiagramPie from '../../Dashboard/WeHelp/WeHelpDiagramPie1';
import SortDiagramLine from '../../Dashboard/WeHelp/multypleDiagramLine/SortDiagramLine';
import WeHelpDiagramLine1 from '../../Dashboard/WeHelp/WeHelpDiagramLine1';
import WeHelpDiagramSmoothLine from '../../Dashboard/WeHelp/WeHelpDiagramSmoothLine';
//import DinamikRapor_AltForms from './DinamikRapor_AltForms/DinamikRapor_AltForms';
import FourthFormList from './FourthFormList';

import MapComponent from './MapComponent';

import Bufer_Fourth_Forms from './classBufer_Fourth_Forms';

const color_1 = '#34C6CD';
const color_2 = '#FF3500';
const color_3 = '#B1F100';
const color_4 = '#FFF500';
const color_5 = '#7908AA';
const color_6 = '#FF7340';
const color_7 = '#FFAB00';
const color_8 = '#009E66';
const color_9 = '#AA66CC';

const colorArr = [color_1, color_2, color_3, color_4, color_5, color_6, color_7, color_8, color_9];

const diagramStyle = {
  height: 'auto',
  marginBottom: '0px',
  paddingBottom: '53px'
};

function setGrafikBarOne (response, componentNumber) {

  let arr = [];
  let maxY = 0;
  for (let i = 0;  i < response["DATA"][componentNumber]["GRAFIK"]["series"].length; i++) {
    arr.push({
      "value": response["DATA"][componentNumber]["GRAFIK"]["series"][i]["data"][0],
      "color": colorArr[i],
      "nameColumn": response["DATA"][componentNumber]["GRAFIK"]["series"][i]["name"],
    });
    if (maxY < response["DATA"][componentNumber]["GRAFIK"]["series"][i]["data"][0]) {
      maxY = response["DATA"][componentNumber]["GRAFIK"]["series"][i]["data"][0];
    }
  }
  
  return <div className='diagramWrapping oneDimensionalDiagram' style={diagramStyle}>
    <WeHelpDiagramOne data={arr} maxY={maxY} />
  </div>
}

function setGrafikBarTwo (response, componentNumber) {
  let arr = [];
  let signature = [];
  let maxY = 0;
  for (let i = 0;  i < response["DATA"][componentNumber]["GRAFIK"]["categories2"].length; i++) {
    let arr1 = [];
    for (let j = 0; j < response["DATA"][componentNumber]["GRAFIK"]["series"].length; j++) {
      console.log('MiddleComponentForThirdForm setGrafikBarTwo arr', response["DATA"][componentNumber]["GRAFIK"]["series"][j]["data"][i]);

      arr1.push({
        "value": response["DATA"][componentNumber]["GRAFIK"]["series"][j]["data"][i],
        "color": colorArr[j],
        "nameColumn": response["DATA"][componentNumber]["GRAFIK"]["series"][j]["name"],
      });
      if (maxY < response["DATA"][componentNumber]["GRAFIK"]["series"][j]["data"][i]) {
        maxY = response["DATA"][componentNumber]["GRAFIK"]["series"][j]["data"][i];
      }
    }
    arr.push(arr1);
  }
  for (let elem of arr[0]) {
    signature.push({
      "color": elem["color"],
      "nameColumn": elem["nameColumn"],
    });
  }
  console.log('MiddleComponentForThirdForm setGrafikBarTwo arr', arr);
  return <div className='diagramWrapping multidimensionalDiagram' style={diagramStyle}>
    <WeHelpDiagramMultiple data={arr} signature={signature} nameColumnGroup={response["DATA"][componentNumber]["GRAFIK"]["categories2"]} maxY={maxY} />
  </div>
}

function setGrafikPie (response, componentNumber) {
  let arr = [];
  let maxY = 0;
  let summOfValues = 0;
  for (let i = 0;  i < response["DATA"][componentNumber]["GRAFIK"]["series"].length; i++) {
    arr.push({
      displayElement: response["DATA"][componentNumber]["GRAFIK"]["series"][i]["name"],
      rate: response["DATA"][componentNumber]["GRAFIK"]["series"][i]["data"][0],
    });
    if (maxY < response["DATA"][componentNumber]["GRAFIK"]["series"][i]["data"][0]) {
      maxY = response["DATA"][componentNumber]["GRAFIK"]["series"][i]["data"][0];
    }
    summOfValues += response["DATA"][componentNumber]["GRAFIK"]["series"][i]["data"][0];
  }

  console.log('MiddleComponentForThirdForm setGrafikPie arr', arr);

  return <div className='diagramWrapping' style={diagramStyle}>
    <WeHelpDiagramPie data={arr} maxY={maxY} onePercent={summOfValues/100} />
  </div>
}

function setGrafikLine1 (response, componentNumber) {
  let arr = [];
  arr[0] = colorArr;
  let arrNamesVariable = [];
  let maxY = 0;
  for (let i = 0; i < response["DATA"][componentNumber]["GRAFIK"]["series"].length; i++) {
    arr.push(response["DATA"][componentNumber]["GRAFIK"]["series"][i]["data"]);
    arrNamesVariable.push(response["DATA"][componentNumber]["GRAFIK"]["series"][i]["name"]);

    let maxVariable = Math.max(...response["DATA"][componentNumber]["GRAFIK"]["series"][i]["data"]);
    if (maxY < maxVariable) {
      maxY = maxVariable;
    }
  }
  arr.push(arrNamesVariable);
  arr.push(response["DATA"][componentNumber]["GRAFIK"]["categories2"]);

  console.log("grafic line 0 " + arr);
  return <div className='diagramWrapping lineDiagram' style={diagramStyle}>
    <WeHelpDiagramLine1 data={arr} maxY={maxY} />
  </div>;
}

function setGrafikLine2 (response, componentNumber) {

  return <div className='diagramWrapping_for_multipleLine' style={diagramStyle}> 
  <WeHelpDiagramSmoothLine dates={response["DATA"][componentNumber]["GRAFIK"]["categories2"]} data={response["DATA"][componentNumber]["GRAFIK"]["series"][0]["data"]} 
  name={response["DATA"][componentNumber]["GRAFIK"]["series"][0]["name"]} color={colorArr[0]} />
  </div>;
}

function setGrafikLines (response, componentNumber) {

  return <SortDiagramLine signature={response["DATA"][componentNumber]["GRAFIK"]["categories2"]} data={response["DATA"][componentNumber]["GRAFIK"]["series"]} />;

}



function MiddleComponentForThirdForm({id, currentValueOfTheDropdownList, response1, response2, refForChangeThirdMiddle}) {
  
  console.time('MiddleComponentForThirdForm');
  //const refHeightSize = useRef(0);
  //const refPreviousValueOfTheDropdownList = useRef(0);
  const [componentNumber, setcomponentNumber] = useState(0);
  const [updateComponent, setupdateComponent] = useState([0]);
  //Bufer_Fourth_Forms.update_fourth_form = setupdateComponent;

  const refSignboard = useRef(0);
  const refFlagSignboard = useRef([false, 0, 0]);

  const refSignboard1 = useRef(null);

  useEffect(() => {
    const signboardDiv0 = refSignboard.current;

    function containerScrolling0 (event) {
      let deltaY = event.deltaY;
      let scrollLeft = signboardDiv0.scrollLeft;
      if (deltaY !== 0 && event.deltaX === 0) {
        if (deltaY > 0) {
          requestAnimationFrame(() => signboardDiv0.scrollLeft = scrollLeft + deltaY);
        } else if (deltaY < 0) {
          requestAnimationFrame(() => signboardDiv0.scrollLeft = scrollLeft + deltaY);
        }
        event.preventDefault();
      }
    }

    const signboardDiv1 = refSignboard1.current;

    function containerScrolling1 (event) {
      let deltaY = event.deltaY;
      let scrollLeft = signboardDiv1.scrollLeft;
      if (deltaY !== 0 && event.deltaX === 0) {
        if (deltaY > 0) {
          requestAnimationFrame(() => signboardDiv1.scrollLeft = scrollLeft + deltaY);
        } else if (deltaY < 0) {
          requestAnimationFrame(() => signboardDiv1.scrollLeft = scrollLeft + deltaY);
        }
        event.preventDefault();
      }
    }

    signboardDiv0.addEventListener('wheel', containerScrolling0);
    signboardDiv1.addEventListener('wheel', containerScrolling1);

    return () => {
      signboardDiv0.removeEventListener('wheel', containerScrolling0);
      signboardDiv1.removeEventListener('wheel', containerScrolling1);
    };
  }, []); 

  if (response1 == undefined || response2?.["DATA"]?.[componentNumber]?.FORM_TURU == undefined) {
    alert('No response from server');
    return;
  }
  
  /*
  const [componentUpdate, setcomponentUpdate] = useState([0]);

  
  const refAltFormFlag = useRef([false]);

  console.log('MiddleComponentForThirdForm refAltFormFlag', refAltFormFlag.current);
  let AltForm;
  if (response["DATA"][componentNumber]["ADI"] == "Tablo") {
    AltForm = <div className='signboard_middleComponentForThirdForm' > 
    {response["DATA"].map(function(item, index) {
      return <div className='stickerAlt' 
      style={{color: componentNumber == index? '#4fa2fc' : '#383839'}}
      onClick={()=> setcomponentNumber(index)}>
        <p className='stickerHeader'> {item.ADI} </p>
      </div>
    })}
    </div>;
  }
  */


  /*
  const [heightSize, setheightSize] = useState('auto');
  if (refPreviousValueOfTheDropdownList.current != currentValueOfTheDropdownList || refForChangeThirdMiddle[0]) {
    refPreviousValueOfTheDropdownList.current = currentValueOfTheDropdownList; 
    refHeightSize.current = 0;
    refForChangeThirdMiddle[0] = false;
    setheightSize('auto');
  }
  
  if (refHeightSize.current == 0) {
    queueMicrotask(function() {
      console.log('middleComponent queueMicrotask document', document.getElementsByClassName('middleComponentConteiner')[0].offsetHeight);
      refHeightSize.current = document.getElementsByClassName('MiddleComponentForThirdForm')[0].offsetHeight + 5;
      setheightSize(`${refHeightSize.current}px`);
    
    // при нажатии кнопки ara размер контейнера не увеличивается
    });
  
    /*
    здесь queueMicrotask используется для нахождения высоты div со стилем auto и 
    повторного рендеринга с уже найденным значением. Если этого не сделать, то в первый раз 
    нельзя установить плавное закрытие div, он закроется мгновенно.
    */
  //} 
  



let componentWithAdditionalInformation;
if (response2 != undefined && response2 != null) {
  console.dir(response2);
  //console.log("FORM_TURU " + response2.DATA[componentNumber].FORM_TURU);
  switch (response2?.["DATA"]?.[componentNumber]?.FORM_TURU){
    case "GRAFIK_BAR_1":
      componentWithAdditionalInformation = setGrafikBarOne (response2, componentNumber);
    break;
    case "GRAFIK_BAR_2":
      componentWithAdditionalInformation = setGrafikBarTwo (response2, componentNumber);
    break;
    case "GRAFIK_PIE":
      componentWithAdditionalInformation = setGrafikPie (response2, componentNumber);
    break;
    case "GRAFIK_LINE_1":
      componentWithAdditionalInformation = setGrafikLine1 (response2, componentNumber);
    break;
    case "GRAFIK_LINE_2":
      componentWithAdditionalInformation = setGrafikLine2 (response2, componentNumber);
    break;
    case "GRAFIK_LINE_3":
      componentWithAdditionalInformation = setGrafikLines (response2, componentNumber);
    break;
    case "DATA_TABLO":
      let dataJson = JSON.parse(response1["DATA"][componentNumber].DATA);
      componentWithAdditionalInformation = <DinamikRaporTable data={dataJson} columnsTable={Object.keys(dataJson[0])} id={id} divFlag={false} currentValueOfTheDropdownList={currentValueOfTheDropdownList} refForChangeThirdMiddle={refForChangeThirdMiddle} index={-1}  altFormFlag={response1.FORM_DETAY.ALT_FORM} dataToDownload={response2.FORM_DETAY.ALT_FORMLAR} />;
    break;
    case "HARITA_LISTE":
      componentWithAdditionalInformation =   <>
      <div className='map'></div>
      <MapComponent class={'map'} HARITA_DATA={JSON.parse(response2.DATA[componentNumber].HARITA_DATA)} />
      </>;
    break;
  }
}

console.timeEnd('MiddleComponentForThirdForm');

return <div>
  <div className='signboard_middleComponentForThirdForm' ref={refSignboard} 
  onMouseDown={(event) => {
    console.log('signboard_middleComponentForThirdForm', refSignboard.current.scrollLeft, event.clientX);
    refFlagSignboard.current = [true, event.clientX, refSignboard.current.scrollLeft];
    }}
  onMouseUp={(event) => {
    console.log('signboard_middleComponentForThirdForm', refFlagSignboard.current);
    refFlagSignboard.current = [false, 0, 0];
  }}
  onMouseLeave={(event) => {
    if(refFlagSignboard.current[0]) {
      console.log('signboard_middleComponentForThirdForm', refFlagSignboard.current);
      refFlagSignboard.current = [false, 0, 0];
    }
  }}
  onMouseMove={(event) => {
    //console.log('signboard_middleComponentForThirdForm', refSignboard.current.scrollLeft, event.clientX);
    //refFlagSignboard.current = [true, event.clientX];
    
    if (refFlagSignboard.current[0]) {
      refSignboard.current.scrollLeft =  refFlagSignboard.current[1] - event.clientX + refFlagSignboard.current[2];
    }
  
  }}
    > 
    {response1["OZET"].map(function(item) {
      return <div className='sticker'>
        <p className='stickerHeader'> {item["BASLIK"]} </p>
        <p className='stickerText'> {item["DEGER"]} </p>
      </div>
    })}
  </div>

    {
      response1.DASHBOARS.map(function (item, index) {
        let dashboardsJson = JSON.parse(item.DATA);
        return <DinamikRaporTable data={dashboardsJson} columnsTable={Object.keys(dashboardsJson[0])} id={id} divFlag={true} currentValueOfTheDropdownList={currentValueOfTheDropdownList} refForChangeThirdMiddle={refForChangeThirdMiddle} name={item.ADI} index={index} altFormFlag={false} dataToDownload={false} />;

      })
    }
  <div className='signboard_middleComponentForThirdForm' ref={refSignboard1}> 
    {response2["DATA"].map(function(item, index) {
      return <div className='stickerAlt' 
      style={{color: componentNumber == index? '#4fa2fc' : '#383839', backgroundColor: item.TAB_RENGI}}
      onClick={()=> setcomponentNumber(index)}>
        <p className='stickerHeader'> {item.ADI} </p>
      </div>
    })}
  </div>
  {componentWithAdditionalInformation}
  <FourthFormList  />
  

</div>;
}

export default MiddleComponentForThirdForm;

/*
  <div className='signboard_middleComponentForThirdForm' > 
    {response2["DATA"].map(function(item, index) {
      return <div className='stickerAlt' 
      style={{color: componentNumber == index? '#4fa2fc' : '#383839', backgroundColor: item.TAB_RENGI}}
      onClick={()=> setcomponentNumber(index)}>
        <p className='stickerHeader'> {item.ADI} </p>
      </div>
    })}
  </div>
  {componentWithAdditionalInformation}

*/

//{response1.FORM_DETAY.ALT_FORM && <DinamikRapor_AltForms id={id} />}//непонятно для чего это нужно
